import rocket from "../../assets/images/rocket.svg";
import Button from "../button/button.component";
import styles from "./navbar.module.scss";

const Navbar = () => {
  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={styles.navbarContainer}>
      <div className={styles.navbarLogoContainer}>
        <img src={rocket} alt="logo" />
        <h1>give it</h1>
      </div>
      <div className={styles.navbarOptionsContainer}>
        <span
          className={styles.navbarOption}
          onClick={() => handleClickScroll("whyGiveIt")}
        >
          ¿Por qué Give It?
        </span>
        <span
          className={styles.navbarOption}
          onClick={() => handleClickScroll("ourServices")}
        >
          Soluciones
        </span>
        <span
          className={styles.navbarOption}
          onClick={() => handleClickScroll("ourBenefits")}
        >
          Beneficios
        </span>
        <span
          className={styles.navbarOption}
          onClick={() => handleClickScroll("prices")}
        >
          Tarifas
        </span>
        <span
          className={styles.navbarOption}
          onClick={() => handleClickScroll("contact")}
        >
          Contacto
        </span>
      </div>
      <Button label="Ingresar" href="https://dashboard.giveit.es" />
    </div>
  );
};

export default Navbar;
