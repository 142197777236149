import { FormEvent, useState } from "react";
import axios from "axios";
import styles from "./App.module.scss";
import Button from "./components/button/button.component";
import Navbar from "./components/navbar/navbar.component";

import rocket from "./assets/images/rocket.svg";
import imgHome from "./assets/images/img-home.png";
import wave from "./assets/images/wave.svg";
import wave2 from "./assets/images/wave2.svg";
import astronaut1 from "./assets/images/astronaut1.png";
import astronaut2 from "./assets/images/astronaut2.png";
import astronaut3 from "./assets/images/astronaut3.png";
import astronaut4 from "./assets/images/astronaut4.png";
import mockupPc from "./assets/images/mockup_pc.png";
import arrowDown from "./assets/images/arrow-down.png";
import planets from "./assets/images/planets.png";
import planets2 from "./assets/images/planets2.png";

import acknowIcon from "./assets/images/acknowIcon.png";
import objetiveIcon from "./assets/images/objetiveIcon.png";
import analitycIcon from "./assets/images/analitycIcon.png";
import rewardIcon from "./assets/images/rewardIcon.png";
import challengeIcon from "./assets/images/challengeIcon.png";
import EmailIcon from "./assets/images/email_icon.png";
import PhoneIcon from "./assets/images/phone_icon.png";
import SocialsIcon from "./assets/images/socials_icon.png";
import FacebookIcon from "./assets/images/facebook.png";
import TwitterIcon from "./assets/images/Twitter.png";
import InstagramIcon from "./assets/images/Instagram.png";
import LinkedinIcon from "./assets/images/LinkedIn.png";
import FacebookWhiteIcon from "./assets/images/Facebook_white.png";
import TwitterWhiteIcon from "./assets/images/Twitter_white.png";
import InstagramWhiteIcon from "./assets/images/Instagram_white.png";
import LinkedinWhiteIcon from "./assets/images/LinkedIn_white.png";

import benefitsIcon1 from "./assets/images/benefit_icon_1.png";
import benefitsIcon2 from "./assets/images/benefit_icon_2.png";
import benefitsIcon3 from "./assets/images/benefit_icon_3.png";
import benefitsIcon4 from "./assets/images/benefit_icon_4.png";
import benefitsIcon5 from "./assets/images/benefit_icon_5.png";
import benefitsIcon6 from "./assets/images/benefit_icon_6.png";
import benefitsIcon7 from "./assets/images/benefit_icon_7.png";

import bigbox from "./assets/images/bigbox.png";
import bigbox_background from "./assets/images/bigbox_background.png";
import gympass from "./assets/images/gympass.png";
import gympass_background from "./assets/images/gympass_background.png";
import pedidosya from "./assets/images/pedidosya.png";
import pedidosya_background from "./assets/images/pedidosya_background.png";

import checkboxDisabled from "./assets/images/checkbox_disabled.png";
import checkbox1 from "./assets/images/checkbox_1.png";
import checkbox2 from "./assets/images/checkbox_2.png";
import checkbox3 from "./assets/images/checkbox_3.png";

function App() {
  const [whyGiveitTextReadMore, setWhyGiveitTextReadMore] = useState(false);
  const [ourServiceTextReadMore, setOurServiceTextReadMore] = useState(false);
  const [ourServicesCardReadMore, setOurServicesCardReadMore] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [emailSended, setEmailSended] = useState(false);

  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: "smooth" });
  };

  const initialForm = {
    fullname: "",
    email: "",
    phone: "",
    message: "",
  };
  const [form, setForm] = useState<{
    fullname: string;
    email: string;
    phone: string;
    message: string;
  }>(initialForm);

  const handlerFormChange = (
    property: keyof {
      fullname: string;
      email: string;
      phone: string;
      message: string;
    },
    value: string
  ) => {
    const formValues = form;
    formValues[property] = value;
    setForm((prev) => {
      return { ...prev, ...formValues };
    });
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    await axios.post("https://api.giveit.es/email/contact", {
      fullname: form.fullname,
      email: form.email,
      phone: form.phone,
      message: form.message,
    });

    setEmailSended(true);
    clearForm();
  };

  const clearForm = () => {
    setForm((prev) => {
      return {
        fullname: "",
        email: "",
        phone: "",
        message: "",
      };
    });
  };

  return (
    <div className={styles.welcomeContainer}>
      <header>
        <Navbar />
      </header>
      <main>
        <div className={styles.homeContainer}>
          <div className={styles.homeContent}>
            <h1>
              <span>Potenciamos el compromiso</span> y el rendimiento de su
              equipo
            </h1>
            <p>
              Pruebe la plataforma preferida por los líderes de recursos
              humanos, gerentes y colaboradores.
            </p>
            <div className={styles.homeButtonsContainer}>
              <Button
                label="Conocer tarifas"
                color="blue"
                href="#prices"
                positionRounded="right"
              />
              <Button
                label="¡Comenzá hoy!"
                color="white"
                href="#contact"
                positionRounded="right"
              />
            </div>
          </div>
          <img src={imgHome} alt="" />
        </div>
        <div className={styles.waveDivider}>
          <img src={wave} alt="wave" />
        </div>
        <div className={styles.doYouKnowContainer}>
          <div className={styles.doYouKnowContent}>
            <img alt="astronaut" src={astronaut1}></img>
            <div className={styles.doYouKnowTextContainer}>
              <span className={styles.doYouKnowTitle}>¿Sabías que?</span>
              <p className={styles.doYouKnowText}>
                <b>El 80%</b> de los colaboradores siente que sus objetivos{" "}
                <b>no están claramente definidos</b> y alineados con los
                objetivos de la organización.
              </p>
              <span className={styles.doYouKnowAquaMark}>
                McKinsey & Company
              </span>
            </div>
          </div>
        </div>
        <div className={styles.aboutUsContainer} id="whyGiveIt">
          <div className={styles.lineBefore} />
          <div className={styles.circleLineContainer}>
            <div className={styles.circleLineInside}></div>
          </div>
          <div className={styles.stepContainer}>
            <div className={styles.stepNumber}>01</div>
            <div className={styles.stepLabel}>Sobre nosotros</div>
          </div>
          <section className={styles.aboutUsContent}>
            <div className={styles.aboutUsTextContainer}>
              <span className={styles.sectionTitle}>¿Por qué Give It?</span>
              <p>
                Impulsamos el reconocimiento y la motivación de los equipos, al
                permitir que los colaboradores reconozcan y recompensen
                fácilmente el excelente trabajo de sus colegas.
                <span
                  style={{ display: whyGiveitTextReadMore ? "block" : "none" }}
                >
                  {" "}
                  Facilitamos la gestión de objetivos y el seguimiento del
                  progreso, lo que ayuda a mantener al equipo enfocado en los
                  objetivos a largo plazo y a asegurar que se sientan valorados
                  y apreciados en su trabajo diario. Además, es fácil de usar y
                  ofrece una experiencia personalizada para cada usuario.
                </span>
                <span
                  className={styles.readMore}
                  onClick={() =>
                    setWhyGiveitTextReadMore(!whyGiveitTextReadMore)
                  }
                >
                  {" "}
                  {whyGiveitTextReadMore ? `Leer menos` : `Leer más`}{" "}
                  <img
                    className={whyGiveitTextReadMore ? styles.rotate : ""}
                    src={arrowDown}
                    alt="arrow-down"
                  />
                </span>
              </p>
            </div>
            <div className={styles.imageContainer}>
              <img src={mockupPc} alt="mockup" />
            </div>
          </section>
        </div>
        <div className={styles.ourServicesContainer} id="ourServices">
          <div className={styles.lineBefore} />
          <div className={styles.circleLineContainer}>
            <div className={styles.circleLineInside}></div>
          </div>
          <div className={styles.stepContainer}>
            <div className={styles.stepNumber}>02</div>
            <div className={styles.stepLabel}>Nuestros servicios</div>
          </div>
          <section className={styles.ourServicesContent}>
            <div className={styles.ourServicesFlexContainer}>
              <div className={styles.ourServicesTextContainer}>
                <span className={styles.sectionTitle}>¿Qué ofrecemos?</span>
                <p>
                  Ofrecemos una variedad de servicios diseñados para mejorar la
                  experiencia de los colaboradores y aumentar su compromiso con
                  la empresa.
                  <span
                    style={{
                      display: ourServiceTextReadMore ? "block" : "none",
                    }}
                  >
                    Al mejorar la experiencia de los colaboradores, las empresas
                    pueden mejorar su productividad, retener el talento y
                    construir una cultura de trabajo positiva.
                  </span>
                  <span
                    className={styles.readMore}
                    onClick={() =>
                      setOurServiceTextReadMore(!ourServiceTextReadMore)
                    }
                  >
                    {" "}
                    {ourServiceTextReadMore ? `Leer menos` : `Leer más`}{" "}
                    <img
                      className={ourServiceTextReadMore ? styles.rotate : ""}
                      src={arrowDown}
                      alt="arrow-down"
                    />
                  </span>
                </p>
              </div>
              <div className={styles.imageContainer}>
                <img src={astronaut2} alt="astronaut" />
              </div>
            </div>
            <div className={styles.ourServicesCardsContainer}>
              <div className={styles.ourServicesCard}>
                <img src={acknowIcon} alt="acknow" />
                <h2>Reconocimientos</h2>
                <p
                  style={{
                    display: ourServicesCardReadMore[0] ? "none" : "block",
                  }}
                >
                  Cada semana se enfoca en un valor específico en base a la
                  cultura de nuestra organización. El colaborador tiene...
                </p>
                <p
                  style={{
                    display: ourServicesCardReadMore[0] ? "block" : "none",
                  }}
                >
                  Cada semana se enfoca en un valor específico en base a la
                  cultura de nuestra organización. El colaborador tiene la
                  oportunidad de reconocer a un compañero que personifique ese
                  valor, otorgándoles un "rocket" como una forma especial de
                  reconocimiento. Es una excelente manera de promover una
                  cultura de reconocimiento y motivación dentro de la
                  organización.
                </p>
                <span
                  className={styles.readMore}
                  onClick={() => {
                    const arr = ourServicesCardReadMore;
                    arr[0] = !arr[0];
                    setOurServicesCardReadMore(Object.assign([], arr));
                  }}
                >
                  {ourServicesCardReadMore[0] ? `Leer menos` : `Leer más`}{" "}
                  <img
                    className={ourServicesCardReadMore[0] ? styles.rotate : ""}
                    src={arrowDown}
                    alt="arrow-down"
                  />
                </span>
              </div>
              <div className={styles.ourServicesCard}>
                <img src={objetiveIcon} alt="acknow" />
                <h2>Objetivos</h2>
                <p
                  style={{
                    display: ourServicesCardReadMore[1] ? "none" : "block",
                  }}
                >
                  Se pueden gestionar los objetivos libremente, con el nivel de
                  profundidad que cada líder crea conveniente.
                </p>
                <p
                  style={{
                    display: ourServicesCardReadMore[1] ? "block" : "none",
                  }}
                >
                  Se pueden gestionar los objetivos libremente, con el nivel de
                  profundidad que cada líder crea conveniente. Los objetivos
                  pueden ser empresariales, de equipo o individuales.
                </p>
                <span
                  className={styles.readMore}
                  onClick={() => {
                    const arr = ourServicesCardReadMore;
                    arr[1] = !arr[1];
                    setOurServicesCardReadMore(Object.assign([], arr));
                  }}
                >
                  {ourServicesCardReadMore[1] ? `Leer menos` : `Leer más`}{" "}
                  <img
                    className={ourServicesCardReadMore[1] ? styles.rotate : ""}
                    src={arrowDown}
                    alt="arrow-down"
                  />
                </span>
              </div>
              <div className={styles.ourServicesCard}>
                <img src={analitycIcon} alt="acknow" />
                <h2>Módulo de análisis</h2>
                <p
                  style={{
                    display: ourServicesCardReadMore[2] ? "none" : "block",
                  }}
                >
                  Con nuestro módulo de análisis, los gerentes y líderes pueden
                  obtener una visión completa de sus equipos y tomar mejores
                  decisiones...
                </p>
                <p
                  style={{
                    display: ourServicesCardReadMore[2] ? "block" : "none",
                  }}
                >
                  Con nuestro módulo de análisis, los gerentes y líderes pueden
                  obtener una visión completa de sus equipos y tomar mejores
                  decisiones informadas para el desempeño de la empresa. Ésto
                  les permitirá hacer un seguimiento personalizado para cada
                  integrante y así fortalecer el equipo y los vínculos.{" "}
                </p>
                <span
                  className={styles.readMore}
                  onClick={() => {
                    const arr = ourServicesCardReadMore;
                    arr[2] = !arr[2];
                    setOurServicesCardReadMore(Object.assign([], arr));
                  }}
                >
                  {ourServicesCardReadMore[2] ? `Leer menos` : `Leer más`}{" "}
                  <img
                    className={ourServicesCardReadMore[2] ? styles.rotate : ""}
                    src={arrowDown}
                    alt="arrow-down"
                  />
                </span>
              </div>
              <div className={styles.ourServicesCard}>
                <img src={rewardIcon} alt="acknow" />
                <h2>Premios</h2>
                <p
                  style={{
                    display: ourServicesCardReadMore[3] ? "none" : "block",
                  }}
                >
                  A medida que el colaborador progresa hay premios previamente
                  establecidos por la empresa, para poder reconocer y premiar el
                  buen...
                </p>
                <p
                  style={{
                    display: ourServicesCardReadMore[3] ? "block" : "none",
                  }}
                >
                  A medida que el colaborador progresa hay premios previamente
                  establecidos por la empresa, para poder reconocer y premiar el
                  buen desempeño. De esta manera logramos una experiencia
                  motivadora. Contamos con distintas alianzas logrando
                  personalizar los premios.
                </p>
                <span
                  className={styles.readMore}
                  onClick={() => {
                    const arr = ourServicesCardReadMore;
                    arr[3] = !arr[3];
                    setOurServicesCardReadMore(Object.assign([], arr));
                  }}
                >
                  {ourServicesCardReadMore[3] ? `Leer menos` : `Leer más`}{" "}
                  <img
                    className={ourServicesCardReadMore[3] ? styles.rotate : ""}
                    src={arrowDown}
                    alt="arrow-down"
                  />
                </span>
              </div>
              <div className={styles.ourServicesCard}>
                <img src={challengeIcon} alt="acknow" />
                <h2>Challenges</h2>
                <p
                  style={{
                    display: ourServicesCardReadMore[4] ? "none" : "block",
                  }}
                >
                  Los desafíos son una forma divertida y efectiva de aumentar la
                  participación de los colaboradores en distintas actividades de
                  la....
                </p>
                <p
                  style={{
                    display: ourServicesCardReadMore[4] ? "block" : "none",
                  }}
                >
                  Los desafíos son una forma divertida y efectiva de aumentar la
                  participación de los colaboradores en distintas actividades de
                  la empresa y fomentar hábitos que lo ayuden a crecer como
                  persona, aumentar el sentido de pertenencia.
                </p>
                <span
                  className={styles.readMore}
                  onClick={() => {
                    const arr = ourServicesCardReadMore;
                    arr[4] = !arr[4];
                    setOurServicesCardReadMore(Object.assign([], arr));
                  }}
                >
                  {ourServicesCardReadMore[4] ? `Leer menos` : `Leer más`}{" "}
                  <img
                    className={ourServicesCardReadMore[0] ? styles.rotate : ""}
                    src={arrowDown}
                    alt="arrow-down"
                  />
                </span>
              </div>
            </div>
          </section>
        </div>
        <div className={`${styles.waveDivider} ${styles.wave2}`}>
          <img src={wave2} alt="wave2" />
          <div className={styles.lineBefore} />
        </div>
        <div className={styles.ourBenefitsContainer} id="ourBenefits">
          <div className={styles.lineBefore} />
          <img
            src={planets}
            alt="planets"
            className={styles.ourBenefitsPlanetsImage}
          />
          <img
            src={astronaut3}
            alt="astronaut"
            className={styles.ourBenefitsAstronautImage}
          />
          <div className={styles.circleLineContainer}>
            <div className={styles.circleLineInside}></div>
          </div>
          <div className={styles.stepContainer}>
            <div className={styles.stepNumber}>03</div>
            <div className={styles.stepLabel}>Give It como herramienta</div>
          </div>
          <section className={styles.ourBenefitsContent}>
            <div className={styles.ourBenefitsTextContainer}>
              <span
                className={`${styles.sectionTitle} ${styles.sectionTitleWhite}`}
              >
                ¡Múltiples beneficios!
              </span>
              <p className={styles.white}>
                Somos conscientes de que el éxito de una empresa está
                estrechamente relacionado con el éxito de sus colaboradores, es
                por eso que nos dedicamos a crear un ambiente laboral en el que
                todos puedan crecer y prosperar juntos.{" "}
              </p>
            </div>
            <div className={styles.ourBenefitsCardsContainer}>
              <div
                className={styles.ourBenefitsCard}
                style={{ backgroundColor: "#1734FE" }}
              >
                <img src={benefitsIcon1} alt="benefits" />
                <h3>Rápida adopción</h3>
              </div>
              <div
                className={styles.ourBenefitsCard}
                style={{ backgroundColor: "#E067FE" }}
              >
                <img src={benefitsIcon2} alt="benefits" />
                <h3>Mayor motivación</h3>
              </div>
              <div
                className={styles.ourBenefitsCard}
                style={{ backgroundColor: "#61B604" }}
              >
                <img src={benefitsIcon3} alt="benefits" />
                <h3>Transparencia</h3>
              </div>
              <div
                className={styles.ourBenefitsCard}
                style={{ backgroundColor: "#FF5C16" }}
              >
                <img src={benefitsIcon4} alt="benefits" />
                <h3>Retención de talentos</h3>
              </div>
              <div
                className={styles.ourBenefitsCard}
                style={{ backgroundColor: "#0094FF" }}
              >
                <img src={benefitsIcon5} alt="benefits" />
                <h3>Mejor clima laboral</h3>
              </div>
              <div
                className={styles.ourBenefitsCard}
                style={{ backgroundColor: "#FFA800" }}
              >
                <img src={benefitsIcon6} alt="benefits" />
                <h3>Mejor desempeño</h3>
              </div>
              <div
                className={styles.ourBenefitsCard}
                style={{ backgroundColor: "#C823A3" }}
              >
                <img src={benefitsIcon7} alt="benefits" />
                <h3>Imagen empresarial positiva</h3>
              </div>
            </div>
          </section>
        </div>
        <div className={styles.moreContainer} id="more">
          <div className={styles.lineBefore} />
          <section className={styles.moreContent}>
            <div className={styles.moreTextContainer}>
              <span
                className={`${styles.sectionTitle} ${styles.sectionTitleWhite}`}
              >
                Además...
              </span>
              <p className={styles.white}>
                A lo largo del recorrido del colaborador, hay premios
                previamente establecidos por la empresa, para poder reconocer y
                premiar el buen desempeño, de esta manera logramos una
                experiencia motivadora. Nosotros contamos con distintas alianzas
                logrando personalizar los premios que se brindan.
              </p>
            </div>
            <img
              src={planets2}
              alt="planets"
              className={styles.morePlanetsImage}
            />
            <img
              src={astronaut4}
              alt="astronaut"
              className={styles.moreAstronautImage}
            />
            <div className={styles.moreCardContainer}>
              <div
                className={styles.moreCard}
                style={{ backgroundImage: `url(${bigbox_background}` }}
              >
                <img src={bigbox} alt="more" />
                <h4>Bigbox</h4>
                <p>¡Hacé un regalo empresarial!</p>
                <span>
                  Fidelizá clientes y colaboradores con Bigbox, hacer un regalo
                  nunca fue tan fácil.
                </span>
              </div>
              <div
                className={styles.moreCard}
                style={{ backgroundImage: `url(${gympass_background}` }}
              >
                <img src={gympass} alt="more" />
                <h4>Gympass</h4>
                <p>Bienestar para tu equipo</p>
                <span>
                  Los colaboradores tienen acceso ilimitado a gimnasios,
                  estudios, clases, entrenamientos, etc.
                </span>
              </div>
              <div
                className={styles.moreCard}
                style={{ backgroundImage: `url(${pedidosya_background}` }}
              >
                <img src={pedidosya} alt="more" />
                <h4>Pedidos ya</h4>
                <p>¿Qué vas a pedir?</p>
                <span>
                  Descuentos exclusivos para colaboradores registrados.
                </span>
              </div>
            </div>
          </section>
        </div>
        <div
          className={`${styles.waveDivider} ${styles.wave2} ${styles.rotate}`}
        >
          <img src={wave2} alt="wave2" className={styles.rotate} />
          <div className={styles.lineBefore} />
        </div>
        <div className={styles.pricesContainer} id="prices">
          <div className={styles.lineBefore} />
          <div className={styles.circleLineContainer}>
            <div className={styles.circleLineInside}></div>
          </div>
          <div className={styles.stepContainer}>
            <div className={styles.stepNumber}>04</div>
            <div className={styles.stepLabel}>¿Como accedo?</div>
          </div>
          <section className={styles.pricesContent}>
            <div className={styles.pricesTextContainer}>
              <span className={`${styles.sectionTitle}`}>Tarifas</span>
              <p>
                Actualmente tenemos 3 tipos de tarifas para ofrecerte, las
                cuales tienen distintos tipos de beneficios y cierta cantidad de
                usuarios permitidos.
                <br />
                ¿Querés probar Give It? ¡Sumate hoy a nuestra plataforma!
              </p>
            </div>
            <div className={styles.priceCardsContainer}>
              <div className={styles.priceCard}>
                <h2>Crew</h2>
                <span>
                  Plan básico hasta <b>10 usuarios.</b>
                </span>
                <ul>
                  <li>
                    <img src={checkbox1} alt="" />
                    Sistema de reconocimientos
                  </li>
                  <li>
                    <img src={checkbox1} alt="" />
                    Organigrama
                  </li>
                  <li>
                    <img src={checkbox1} alt="" />
                    Gestión de objetivos
                  </li>
                  <li>
                    <img src={checkbox1} alt="" />
                    Feedback
                  </li>
                  <li>
                    <img src={checkbox1} alt="" />
                    Parametrización
                  </li>
                  <li className={styles.disabled}>
                    <img src={checkboxDisabled} alt="" />
                    Perfiles
                  </li>
                  <li className={styles.disabled}>
                    <img src={checkboxDisabled} alt="" />
                    Challenges
                  </li>
                  <li className={styles.disabled}>
                    <img src={checkboxDisabled} alt="" />
                    Beneficios
                  </li>
                  <li className={styles.disabled}>
                    <img src={checkboxDisabled} alt="" />
                    Pedidos Ya
                  </li>
                  <li className={styles.disabled}>
                    <img src={checkboxDisabled} alt="" />
                    Big box
                  </li>
                  <li className={styles.disabled}>
                    <img src={checkboxDisabled} alt="" />
                    Merchandising
                  </li>
                  <li className={styles.disabled}>
                    <img src={checkboxDisabled} alt="" />
                    Gympass
                  </li>
                </ul>
                <button
                  style={{ backgroundColor: "#384FF0", cursor: "pointer" }}
                  onClick={() => {
                    setForm({
                      fullname: "",
                      email: "",
                      phone: "",
                      message: "¡Hola! Quiero acceder al plan gratuito...",
                    });
                    window.location.href = "#contact";
                  }}
                >
                  Free
                </button>
              </div>
              <div className={styles.priceCard}>
                <h2 style={{ color: "#FF6B00" }}>Pilot</h2>
                <span>
                  Plan básico hasta <b>ilimitados.</b>
                </span>
                <ul>
                  <li>
                    <img src={checkbox2} alt="" />
                    Sistema de reconocimientos
                  </li>
                  <li>
                    <img src={checkbox2} alt="" />
                    Organigrama
                  </li>
                  <li>
                    <img src={checkbox2} alt="" />
                    Gestión de objetivos
                  </li>
                  <li>
                    <img src={checkbox2} alt="" />
                    Feedback
                  </li>
                  <li>
                    <img src={checkbox2} alt="" />
                    Parametrización
                  </li>
                  <li>
                    <img src={checkbox2} alt="" />
                    Perfiles
                  </li>
                  <li>
                    <img src={checkbox2} alt="" />
                    Challenges
                  </li>
                  <li>
                    <img src={checkbox2} alt="" />
                    Beneficios
                  </li>
                  <li>
                    <img src={checkbox2} alt="" />
                    Pedidos Ya
                  </li>
                  <li>
                    <img src={checkbox2} alt="" />
                    Big box
                  </li>
                  <li>
                    <img src={checkbox2} alt="" />
                    Merchandising
                  </li>
                  <li className={styles.disabled}>
                    <img src={checkboxDisabled} alt="" />
                    Gympass
                  </li>
                </ul>
                <button
                  style={{ backgroundColor: "#FF6B00", cursor: "pointer" }}
                  onClick={() => {
                    setForm({
                      fullname: "",
                      email: "",
                      phone: "",
                      message:
                        "¡Hola! Quiero que me contacten por el Plan Pilot...",
                    });
                    window.location.href = "#contact";
                  }}
                >
                  Ver precios
                </button>
              </div>
              <div className={styles.priceCard}>
                <h2 style={{ color: "#FFA800" }}>Astronaut</h2>
                <span>
                  Plan básico hasta <b>ilimitados.</b>
                </span>
                <ul>
                  <li>
                    <img src={checkbox3} alt="" />
                    Sistema de reconocimientos
                  </li>
                  <li>
                    <img src={checkbox3} alt="" />
                    Organigrama
                  </li>
                  <li>
                    <img src={checkbox3} alt="" />
                    Gestión de objetivos
                  </li>
                  <li>
                    <img src={checkbox3} alt="" />
                    Feedback
                  </li>
                  <li>
                    <img src={checkbox3} alt="" />
                    Parametrización
                  </li>
                  <li>
                    <img src={checkbox3} alt="" />
                    Perfiles
                  </li>
                  <li>
                    <img src={checkbox3} alt="" />
                    Challenges
                  </li>
                  <li>
                    <img src={checkbox3} alt="" />
                    Beneficios
                  </li>
                  <li>
                    <img src={checkbox3} alt="" />
                    Pedidos Ya
                  </li>
                  <li>
                    <img src={checkbox3} alt="" />
                    Big box
                  </li>
                  <li>
                    <img src={checkbox3} alt="" />
                    Merchandising
                  </li>
                  <li>
                    <img src={checkbox3} alt="" />
                    Gympass
                  </li>
                </ul>
                <button
                  style={{ backgroundColor: "#FFA800", cursor: "pointer" }}
                  onClick={() => {
                    setForm({
                      fullname: "",
                      email: "",
                      phone: "",
                      message:
                        "¡Hola! Quiero que me contacten por el Plan Astronaut...",
                    });
                    window.location.href = "#contact";
                  }}
                >
                  Ver precios
                </button>
              </div>
            </div>
          </section>
        </div>
        <div className={styles.pricesContainer} id="contact">
          <div className={styles.lineBefore} />
          <div className={styles.circleLineContainer}>
            <div className={styles.circleLineInside}></div>
          </div>
          <div className={styles.stepContainer}>
            <div className={styles.stepNumber}>05</div>
            <div className={styles.stepLabel}>Contacto</div>
          </div>
          <section className={styles.pricesContent}>
            <div className={styles.pricesTextContainer}>
              <span className={`${styles.sectionTitle}`}>Programar demo</span>
              <p>
                Si tiene preguntas o desea probar nuestros servicios, nuestros
                expertos estarán a su disposición para responder a todas sus
                preguntas y contarle más sobre los beneficios de Give It.
              </p>
            </div>
            <div className={styles.contactContainer}>
              <div className={styles.formContainer}>
                <span>Tus datos</span>
                <p>
                  Por favor, completa los campos con la información necesaria
                  para poder asesorarte de la mejor manera posible.
                </p>
                <form onSubmit={onSubmit}>
                  <div>
                    <label htmlFor="name">
                      Nombre <span>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      placeholder="Nombre"
                      name="name"
                      value={form.fullname}
                      onChange={(e) =>
                        handlerFormChange("fullname", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="email">
                      Email <span>*</span>
                    </label>
                    <input
                      type="email"
                      required
                      placeholder="ejemplo@gmail.com"
                      name="email"
                      value={form.email}
                      onChange={(e) =>
                        handlerFormChange("email", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="phone">Teléfono</label>
                    <input
                      type="phone"
                      placeholder="+54 2231231234"
                      name="phone"
                      value={form.phone}
                      required
                      onChange={(e) =>
                        handlerFormChange("phone", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="message">
                      Mensaje <span>*</span>
                    </label>
                    <textarea
                      placeholder="Escriba el mensaje aquí..."
                      name="message"
                      required
                      value={form.message}
                      onChange={(e) =>
                        handlerFormChange("message", e.target.value)
                      }
                    ></textarea>
                  </div>
                  {emailSended && (
                    <div
                      style={{
                        marginTop: 10,
                        padding: 15,
                        color: "#0f5132",
                        backgroundColor: "#d1e7dd",
                        border: "1px solid #badbcc",
                        borderRadius: 5,
                      }}
                    >
                      ¡Gracias! En la brevedad nos pondremos en contacto.
                    </div>
                  )}
                  <button type="submit" style={{ cursor: "pointer" }}>
                    Pedir demo
                  </button>
                </form>
              </div>
              <div className={styles.contactChannelsContainer}>
                <div className={styles.contactChannelContent}>
                  <div>
                    <img src={EmailIcon} alt="" />
                  </div>
                  <div className={styles.contactChannelInfo}>
                    <span className={styles.contactChannelTitle}>Email</span>
                    <span className={styles.contactChannelValue}>
                      comercial@giveit.es
                    </span>
                  </div>
                </div>
                <div className={styles.contactChannelContent}>
                  <div>
                    <img src={PhoneIcon} alt="" />
                  </div>
                  <div className={styles.contactChannelInfo}>
                    <span className={styles.contactChannelTitle}>Teléfono</span>
                    <span className={styles.contactChannelValue}>
                      +54 9 351 760-0714
                    </span>
                  </div>
                </div>
                <div className={styles.contactChannelContent}>
                  <div>
                    <img src={SocialsIcon} alt="" />
                  </div>
                  <div className={styles.contactChannelInfo}>
                    <span className={styles.contactChannelTitle}>Social</span>
                    <div style={{ display: "flex", gap: 10 }}>
                      <a
                        href="https://www.instagram.com/giveit.hr/"
                        target="_blank"
                      >
                        <img src={InstagramIcon} alt="" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/giveithr/"
                        target="_blank"
                      >
                        <img src={LinkedinIcon} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <footer>
        <div className={styles.footerGiveIt}>
          <div className={styles.footerLogoContainer}>
            <img src={rocket} alt="" />
            <h1>give it</h1>
          </div>
          <div className={styles.footerOptionContainer}>
            <span
              className={styles.footerOption}
              onClick={() => handleClickScroll("whyGiveIt")}
            >
              ¿Por qué Give It?
            </span>
            <span
              className={styles.footerOption}
              onClick={() => handleClickScroll("ourServices")}
            >
              Soluciones
            </span>
            <span
              className={styles.footerOption}
              onClick={() => handleClickScroll("ourBenefits")}
            >
              Beneficios
            </span>
            <span
              className={styles.footerOption}
              onClick={() => handleClickScroll("prices")}
            >
              Tarifas
            </span>
            <span
              className={styles.footerOption}
              onClick={() => handleClickScroll("contact")}
            >
              Contacto
            </span>
          </div>
        </div>
        <div
          style={{
            paddingTop: 45,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className={styles.copyRight}>
            © 2023 Give It. All rights reserved.
          </div>
          <div style={{ display: "flex", gap: 25 }}>
            <a href="https://www.instagram.com/giveit.hr/" target="_blank">
              <img src={InstagramWhiteIcon} alt="" />
            </a>
            <a
              href="https://www.linkedin.com/company/giveithr/"
              target="_blank"
            >
              <img src={LinkedinWhiteIcon} alt="" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
