import styles from './button.module.scss';

interface ButtonProps {
    label: string;
    color?: 'green' | 'white' | 'blue';
    rounded?: boolean;
    href?: string;
    positionRounded?: 'left' | 'right';
}

const selectedColor = (color: 'green' | 'white' | 'blue') => {
    switch(color){
        case 'green':
            return styles.buttonGreen;
        
        case 'white':
            return styles.buttonWhite;

        case 'blue':
            return styles.buttonBlue;

        default: 
        return styles.buttonGreen;
    }
}

const Button = ({ label, color = 'green', rounded = true, href = '#', positionRounded='left' }: ButtonProps) => {
    return <a  href={href} className={`${styles.buttonContainer} ${selectedColor(color)} ${rounded ? positionRounded === 'left' ? styles.buttonRoundedLeft : styles.buttonRoundedRight : styles.buttonBordered}`}>{ label }</a>
}

export default Button;